<template>
  <div>
    <title>SIM INVENTORY ~ ADD PURCHASE ORDER DATA</title>
    <section class="content-header">
      <h1>
        Add Purchase Order Data
        <br />
        <h4>Please enter purchase order transaction data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Purchase Order Transaction</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <!-- left column -->
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Purchase Order Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">PO Number</label>
                  <input
                    type="text"
                    v-model="isidata.ponumber"
                    autocomplete="off"
                    class="form-control"
                    placeholder="PO Number"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">PO Type</label>
                  <select class="form-control" v-model="isidata.typepo">
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="datajenispo in jenispo"
                      :key="datajenispo.id"
                      :value="datajenispo.value"
                    >
                      {{ datajenispo.value }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Supplier</label>
                  <select2
                    :data="suplist"
                    :value="valuesup"
                    @update="updatesup($event)"
                  ></select2>

                  <!-- <select
                    class="form-control"
                    v-model="isidata.kodesupplier"
                    id="kdsup"
                  >
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="listsupp in suplist"
                      :key="listsupp.kode_supplier"
                      :value="listsupp.kode_supplier"
                    >
                      {{ listsupp.nama_supplier }}
                    </option>
                  </select> -->
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">PO Date</label>
                  <input
                    type="date"
                    v-model="isidata.podate"
                    autocomplete="off"
                    class="form-control"
                    placeholder="PO Date"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Delivery Date</label>
                  <input
                    type="date"
                    v-model="isidata.delivdate"
                    autocomplete="off"
                    class="form-control"
                    placeholder="PO Date"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Purchase Order Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Shipment Term</label>
                  <select class="form-control" v-model="isidata.shipterm">
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="datashipmentterm in shipmentterm"
                      :key="datashipmentterm.id"
                      :value="datashipmentterm.value"
                    >
                      {{ datashipmentterm.value }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">CMT / PO</label>
                  <select class="form-control" v-model="isidata.cmtorpo">
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="datacmtpo in cmtpo"
                      :key="datacmtpo.id"
                      :value="datacmtpo.value"
                    >
                      {{ datacmtpo.value }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="exampleInputEmail1">Quotation Number</label>
                  <input
                    type="text"
                    v-model="isidata.quotnumb"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Quotation Number"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.notespo"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes PO Headers"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Master Item Data</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-3">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <!--<div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by item code"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-1">
                    <button
                      @click="searchdata()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div> -->
                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Unit</th>
                      <th>Item Type</th>
                      <th>Notes</th>
                      <th>Qty</th>
                      <th>Price</th>
                      <th>Unit Price</th>
                      <th>Qty PO</th>
                      <th>Unit PO</th>
                      <th>Convertion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classbrg, index) in list_pagedBrg"
                      :key="classbrg.id"
                    >
                      <td>
                        {{ index + 1 + (page - 1) * pageSize }}
                      </td>
                      <!-- <td>{{ classbrg.idx }}</td> -->
                      <td>{{ classbrg.kode_barang }}</td>
                      <td>{{ classbrg.nama_barang }}</td>
                      <td>{{ classbrg.unit }}</td>
                      <td>{{ classbrg.tipe }}</td>
                      <td>
                        <input
                          :id="'inpn-' + classbrg.id"
                          type="text"
                          class="form-control"
                          v-model="classbrg.notespod"
                          :name="'nmn-' + classbrg.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpq-' + classbrg.id"
                          type="text"
                          placeholder="ex : 3"
                          required=""
                          class="form-control"
                          v-model="classbrg.qty"
                          :name="'nmq-' + classbrg.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpp-' + classbrg.id"
                          type="text"
                           placeholder="ex : 3500"
                          required=""
                          class="form-control"
                          v-model="classbrg.pricepod"
                          :name="'nmp-' + classbrg.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpu-' + classbrg.id"
                          type="text"
                          class="form-control"
                           placeholder="ex : Rp"
                          required=""
                          v-model="classbrg.unitpricepod"
                          :name="'nmu-' + classbrg.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpqp-' + classbrg.id"
                          type="text"
                          class="form-control"
                           placeholder="ex : 3"
                          required=""
                          v-model="classbrg.qtypod"
                          :name="'nmqp-' + classbrg.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpup-' + classbrg.id"
                          type="text"
                          class="form-control"
                           placeholder="ex : PCS / KG / etc."
                          required=""
                          v-model="classbrg.unitpod"
                          :name="'nmup-' + classbrg.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inpc-' + classbrg.id"
                          type="text"
                          class="form-control"
                           placeholder="ex : 1 / 2 / 3"
                          required=""
                          v-model="classbrg.konversi"
                          :name="'nmc-' + classbrg.id"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Unit</th>
                      <th>Item Type</th>
                      <th>Notes</th>
                      <th>Qty</th>
                      <th>Price</th>
                      <th>Unit Price</th>
                      <th>Qty PO</th>
                      <th>Unit PO</th>
                      <th>Convertion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="13" class="text-center">
                          <b style="color:red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>
            <!-- /.box-body -->
          </div>
          <button @click="saveData()" type="button" class="btn btn-primary">
            <i class="fa fa-save"></i>
            Save
          </button>
          &nbsp;
          <router-link to="/po">
            <button class="btn btn-success">
              <i class="fa fa-angle-double-left"></i> Back
            </button>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "adddtpo",
  components: {
    VPagination
  },
  data() {
    return {
      /* jenispo:[
        {value:'produksi',id:1},
        {value:'non produksi',id:2},
        {value:'general',id:3},
      ], */
      jenispo: [
        { value: "Material", id: 1 },
        { value: "Auxiliary Raw Material", id: 2 },
        { value: "General", id: 3 }
      ],
      shipmentterm: [
        { value: "FOB SEMARANG", id: 1 },
        { value: "FRANCO KENDAL", id: 2 },
        { value: "EX WORK", id: 3 },
        { value: "LOCAL", id: 4 }
      ],
      cmtpo: [
        { value: "CMT", id: 1 },
        { value: "PO", id: 2 },
      ],
      suplist: [],
      list_pagedBrg: [],
      loading: false,
      isExist: true,
      isidata: {
        ponumber: "",
        kodesupplier: "",
        notespo: "",
        podate: "",
        kodebarang: "",
        namabarang: "",
        typepo: "",
        unit: "",
        delivdate: "",
        shipterm: "",
        cmtorpo: "",
        quotnumb: "",
      },
      classbrg: {
        notespod: "",
        qty: "",
        pricepod: "",
        unitpricepod: "",
        qtypod: "",
        unitpod: "",
        konversi: "",
        unit: ""
      },
      valuesup: "",
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500]
    };
  },
  created() {
    this.getnow();
    this.getSupplierList();
    this.listGridBrg();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.listGridBrg();
    },
    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.listGridBrg();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    getnow() {
      const today = new Date();
      const tahun = today.getFullYear();
      var month = today.getMonth();
      if (String(month).length == 1) {
        month = "0" + (month + 1);
      } else {
        month;
      }
      var tgl = today.getDate();
      if (String(tgl).length == 1) {
        tgl = "0" + tgl;
      } else {
        tgl;
      }

      var delivtgl = today.getDate();
      if (String(delivtgl).length == 1) {
        delivtgl = "0" + (delivtgl + 7);
      } else {
        delivtgl = delivtgl + 7;
      }
      // const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      this.isidata.podate = tahun + "-" + month + "-" + tgl;
      this.isidata.delivdate = tahun + "-" + month + "-" + delivtgl;
    },
    updatesup(value){
      this.valuesup = value;
    },
    listGridBrg() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getalldatabarang?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl +
        "barang/getalldatabarang?length=" +
        this.pageSize +
        "&page=" +
        this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            localStorage.setItem("dtmaterial", JSON.stringify(resp.data.data));
            this.list_pagedBrg = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.reponse.data);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    /* getbarang() {
      this.loading = true;
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getbarangbykode_barang?kode_barang=" + this.isidata.kodebarang;
      const urlAPIget =
        this.$apiurl +
        "barang/getbarangbykode_barang?kode_barang=" +
        this.isidata.kodebarang;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Item Code not found",
              showConfirmButton: false
            });
          } else {
            this.isidata.kodebarang = resp.data.data.kode_barang;
            this.isidata.namabarang = resp.data.data.nama_barang;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    }, */

    getSupplierList() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getalldatasupplier?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl + "supplier/getalldatasupplier?length=55000&page=1";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.suplist = resp.data.data;
          this.suplist.forEach((item) => {
            item.value = item.kode_supplier;
            item.label = item.nama_supplier;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          })
        });
    },

    async saveData() {
      this.loading = true;
      const kode = this.isidata.ponumber;
      const kodesup = this.valuesup;
      const notespo = this.isidata.notespo;
      const jenpo = this.isidata.typepo;
      const podate = this.isidata.podate;
      const termship = this.isidata.shipterm;
      const datedeliv = this.isidata.delivdate;
      const poorcmt = this.isidata.cmtorpo;
      const numbquot = this.isidata.quotnumb;

      const kodeuser = sessionStorage.getItem("kodeuser");
      const tokenlog = sessionStorage.getItem("token");
      const datagetGridBrg = JSON.parse(
        localStorage.getItem("dtmaterial") || "[]"
      );

      const paramdata = {
        po_number: kode,
        kode_supplier: kodesup,
        notes: notespo,
        po_date: podate,
        kode_user: kodeuser,
        jenis_po: jenpo,
        shipment_term: termship,
        delivery_date: datedeliv,
        cmt_or_po: poorcmt,
        quotation_number: numbquot,
      };

      const urlAPIsavedata = this.$apiurl + "po_header/savepo_header";

      const headers = {
        Accept: "application/json",
        Authorization: tokenlog
      };
      axios
        .post(urlAPIsavedata, paramdata, { headers })
        .then(() => {
          for (let i in datagetGridBrg) {
            const paramdatadetail = {
              po_number: this.isidata.ponumber,
              kode_barang: datagetGridBrg[i].kode_barang,
              notes: document.getElementById("inpn-" + datagetGridBrg[i].id)
                .value,
              qty: document.getElementById("inpq-" + datagetGridBrg[i].id)
                .value,
              price: document.getElementById("inpp-" + datagetGridBrg[i].id)
                .value,
              unit_price: document.getElementById(
                "inpu-" + datagetGridBrg[i].id
              ).value,
              unit: datagetGridBrg[i].unit,
              qty_po: document.getElementById("inpqp-" + datagetGridBrg[i].id)
                .value,
              unit_po: document.getElementById("inpup-" + datagetGridBrg[i].id)
                .value,
              konversi: document.getElementById("inpc-" + datagetGridBrg[i].id)
                .value,
              kode_user: kodeuser
            };
            const tokenlogin = sessionStorage.getItem("token");
            const urlAPIsavedatadet = this.$apiurl + "po_detail/savepo_detail";
            const headers = {
              Accept: "application/json",
              Authorization: tokenlogin
            };

            if ((Number(paramdatadetail.qty) > 0) && (Number(paramdatadetail.price) > 0) && ((paramdatadetail.unit_price) != '') && (Number(paramdatadetail.qty_po) > 0) && ((paramdatadetail.unit_po) != '') && (Number(paramdatadetail.konversi) > 0) ) {
              axios
                .post(urlAPIsavedatadet, paramdatadetail, { headers })
                .then(() => {
                  swal({
                    icon: "success",
                    title: "Success",
                    text: "Save data successfully",
                    showConfirmButton: false
                  });
                  this.loading = false;
                })
                .catch((err) => {
                  swal({
                    icon: "warning",
                    title: "Warning",
                    text: err.response.data,
                    showConfirmButton: false
                  });
                  console.log(err);
                  this.loading = false;
                });
            }
          }
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err,
            showConfirmButton: false
          });
          this.loading = false;
        });
    }
    /* async saveData() {
      this.loading = true;
      var kode = this.isidata.ponumber;
      var kodesup = this.isidata.kodesupplier;
      var notespo = this.isidata.notespo;
      var jenpo = this.isidata.typepo;
      var podate = this.isidata.podate;
      var kodebarang = this.isidata.kodebarang;
      var namabarang = this.isidata.namabarang;
      var notespod = this.isidata.notespod;
      var qty = this.isidata.qty;
      var qtypod = this.isidata.qtypod;
      var unit = this.isidata.unit;
      var pricepod = this.isidata.pricepod;
      var unitpricepod = this.isidata.unitpricepod;
      var unitpo = this.isidata.unitpod;
      var konv = this.isidata.konversi;

      if (kode == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "PO Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (kodesup == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Supplier Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (notespo == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes PO can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (jenpo == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "PO Type can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (podate == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "PO Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (kodebarang == "" || namabarang == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item Code / Item Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (notespod == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes PO Detail can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (qtypod == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty PO can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (qty == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (unitpo == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Unit PO can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (unit == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Unit can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (pricepod == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Price PO Detail can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (unitpricepod == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Unit Price PO Detail can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (konv == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Convertion can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        var kodeuser = sessionStorage.getItem("kodeuser");
        var paramdata = {
          po_number: kode,
          kode_supplier: kodesup,
          notes: notespo,
          po_date: podate,
          kode_user: kodeuser,
          jenis_po: jenpo
        };

        var paramdatadetail = {
          po_number: kode,
          kode_barang: kodebarang,
          notes: notespod,
          qty: qty,
          unit: unit,
          price: pricepod,
          unit_price: unitpricepod,
          kode_user: kodeuser,
          qty_po: qtypod,
          unit_po: unitpo,
          konversi: konv
        };

        var tokenlogin = sessionStorage.getItem("token");
        // var urlAPIsavebrg = "http://26.183.23.191/inventory/backend/inventory/api/barang/savebarang";
        var urlAPIsavedata = this.$apiurl + "po_header/savepo_header";
        var urlAPIsavedatadet = this.$apiurl + "po_detail/savepo_detail";

        var headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavedata, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              axios
                .post(urlAPIsavedatadet, paramdatadetail, { headers })
                .then((resp) => {
                  if (resp.data.status == true) {
                    swal({
                      icon: "success",
                      title: "Success",
                      text: "Save data successfully",
                      showConfirmButton: false
                    });
                    this.$router.push({ name: "po" });
                  } else {
                    swal({
                      icon: "warning",
                      title: "Warning",
                      text: "Save data failed",
                      showConfirmButton: false
                    });
                    this.loading = false;
                  }
                })
                .catch((err) => {
                  console.log(err);
                  this.loading = false;
                });
              this.isidata.notespo = "";
              this.isidata.kodebarang = "";
              this.isidata.namabarang = "";
              this.isidata.notespod = "";
              this.isidata.qtypod = "";
              this.isidata.unitpod = "";
              this.isidata.pricepod = "";
              this.isidata.unitpricepod = "";
              this.loading = false;
              this.$router.push({ name: "po" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    } */
  }
};
</script>
